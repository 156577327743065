.x-header
  display: flex
  align-items: center
  background-color: var(--primary-white)
  height: 64px
  border-bottom: 1px solid var(--divider-dark-grey)
  position: relative
  z-index: 10010
  &__logo
    display: flex
    align-items: center
    justify-content: center
    flex: 0 0 auto
    width: 80px
    height: 100%
    color: var(--on-primary-dark)
  &__badges
    display: flex
    align-items: flex-start
    justify-content: space-between
    flex: 1 1 auto
    padding: 16px 24px 16px 14px
  &__profile
    height: 100%
    position: relative
    &-handle
      +preset(3)
      font-weight: 600
      display: flex
      align-items: center
      justify-content: center
      border-left: 1px solid var(--divider-dark-grey)
      padding: 0 24px
      height: 100%
      white-space: nowrap
      color: var(--on-primary-dark)
      &-icon
        margin-right: 8px
        flex: 0 0 auto
        color: var(--icon-dark-grey)
        font-size: 0
        line-height: 0
    &-menu
      display: none
      position: absolute
      top: 100%
      right: 0
      min-width: 100%
      white-space: nowrap
      background-color: var(--primary-white)
      margin-top: 1px
    &:hover &-menu
      display: block
