import { Component, Host, Prop, State, Element, h } from '@stencil/core';

@Component({
  tag: 'x-header',
  styleUrl: 'x-header.sass',
})
export class XHeader {
  @Element() el: HTMLElement;

  @Prop() homepage: string = '/';
  @Prop() username: string;

  @State() withBadges: boolean;
  @State() withProfile: boolean;

  connectedCallback() {
    this.withBadges = !!this.el.querySelector('[slot="badges"]');
    this.withProfile = !!this.username && !!this.el.querySelector('[slot="menu"]');
  }

  render() {
    return (
      <Host class="x-header">
        <a href={this.homepage} class="x-header__logo">
          <slot name="logo"></slot>
        </a>
        {this.withBadges && (
          <div class="x-header__badges">
            <slot name="badges"></slot>
          </div>
        )}
        {this.withProfile && (
          <div class="x-header__profile">
            <div class="x-header__profile-handle">
              <div class="x-header__profile-handle-icon">
                <x-icon glyph="human" size="12"></x-icon>
              </div>
              {this.username}
            </div>
            <div class="x-header__profile-menu">
              <slot name="menu"></slot>
            </div>
          </div>
        )}
      </Host>
    );
  }
}
